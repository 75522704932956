/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { createContext, useState, useContext, useEffect } from 'react'
import moment from 'moment'
import { AuthContext } from './AuthProvider.js'
import { getDateTimeRangeToEnd } from '../functionTool/functionTool.js'

export const DashboardContext = createContext()

export default function DashboardProvider({ children }) {
    const { user } = useContext(AuthContext)
    const isDemoAccount = user?.userName === '展示' && process.env.REACT_APP_STAGE_NAME !== '正式區' ? true : false //展示帳號
    const [customTimeLineMode, setCustomTimeLineMode] = useState(false)
    const [endOfThatDay, setEndOfThatDay] = useState(false)
    const [listID, setListID] = useState('')
    const [date, setDate] = useState(moment())
    const [refLine, setRefLine] = useState({
        active: false,
        payload: null,
    })
    const { startDatetime, endDatetime } = getDateTimeRangeToEnd('day', date) //日、週、月、年開始時間、結束時間
    const [offset, setOffset] = useState({
        refAreaLeft: '',
        refAreaRight: '',
        left: 0,
        right: 1440,
    })
    const [section, setSection] = useState({
        left: 0,
        right: 0,
        fetch: false,
    })

    useEffect(() => {
        if (isDemoAccount) setCustomTimeLineMode(true)
    },[isDemoAccount])

    const querys = {
        date,
        setDate,
        listID,
        setListID,
        refLine,
        setRefLine,
        startDatetime,
        endDatetime,
        offset,
        setOffset,
        section,
        setSection,
        customTimeLineMode,
        setCustomTimeLineMode,
        endOfThatDay,
        setEndOfThatDay,
    }
    // console.log(querys)
    return (
        <DashboardContext.Provider value={querys}>
            {children}
        </DashboardContext.Provider>
    )
}
